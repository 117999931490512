#grid-container {
    display: flex;
    flex-wrap: wrap;

}

.neonText {
    color: #fff;
    text-shadow: 0 0 7px #fff, 0 0 42px #0fa, 0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa;
}

.tilt-neon-one {
    font-family: "Tilt Neon", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: "XROT" 0, "YROT" 0;
}

.square {
    width: 24px;
    height: 14px;
    border: 1px solid #eee;
    background-color: #360063;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
}

.active {
    background-color: #d36600; /* Change color for activated squares */
}

#background-loading {
    background-color: rgb(0, 0, 0);
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
}

.wrapper {
    width: 200px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.circle {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    left: 15%;
    transform-origin: 50%;
    animation: circle .5s alternate infinite ease;
}

@keyframes circle {
    0% {
        top: 60px;
        height: 5px;
        border-radius: 50px 50px 25px 25px;
        transform: scaleX(1.7);
    }
    40% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }
    100% {
        top: 0;
    }
}

.shadow {
    width: 20px;
    height: 4px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 62px;
    left: 15%;
    z-index: -1;
    transform-origin: 50%;
    animation: shadow .5s alternate infinite ease;
}

@keyframes shadow {
    0% {
        transform: scaleX(1.5);
    }
    40% {
        transform: scaleX(1);
        opacity: .7;
    }
    100% {
        transform: scaleX(.2);
        opacity: .4;
    }
}

.container {
    padding: 20px; /* Add padding to the container */
    border: 2px solid #fff; /* Add white border to the container */
    border-radius: 10px; /* Add rounded corners */
    background-color: #282c34; /* Add background color */
    margin-bottom: 20px; /* Add margin at the bottom */
}

.col-12 {
    padding: 20px; /* Increase padding for better spacing */
    margin: 10px; /* Add margin between columns */
    border: 2px solid #fff; /* Add white border to columns */
    border-radius: 10px; /* Add rounded corners */
    background-color: #1a1a1a; /* Add background color to columns */
}

.table-dark {
    background-color: #1a1a1a;
    color: #fff;
    border: 2px solid #fff; /* Add white outline */
}

.table-dark th, .table-dark td {
    border: 1px solid #333;
}

.table-dark th {
    background-color: #333;
}

.table-dark tr:nth-child(even) {
    background-color: #2a2a2a;
}

.table-dark tr:hover {
    background-color: #444;
}

.btn {
    margin: 5px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
}

.form-control {
    margin: 5px 0;
}

.p-3 {
    padding: 1rem !important;
}

img {
  max-width: 120px;
  max-height: 120px;
}

.inventory-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px; /* Adjust the gap between images as needed */
}